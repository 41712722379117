import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { DatePipe } from '@shared/sb-lib/calendar/pipes/date.pipe';
import { isAfter, isBefore, isEqual, isSameDay, startOfDay } from 'date-fns';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { NPeriodService } from '../../../../+authenticated/+schedule/shared/schedule-actions/n-period.service';
import { format, getPeriod, nextDate, parseDate, previousDate } from '../../../date.helper';
import { PeriodType } from '../../../interfaces';

@Component({
  selector: 'date-pager',
  templateUrl: './date-pager.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconComponent, DatePipe, OverlayPanelModule, CalendarModule, FormsModule],
})
export class DatePagerComponent implements OnInit, OnDestroy {
  public datePickerDate: Date;

  @Input()
  public layout: 'icon' | 'text' = 'text';

  @Input()
  public hasLoadingState = false;
  public periodStart: Date;
  public periodEnd: Date;
  @Input()
  public delay = true;
  @Output()
  public dateChange = new EventEmitter<string>();
  @ViewChild(OverlayPanel)
  public overlayPanel: OverlayPanel;
  public dateFormat: string;
  public isLoading: boolean;
  public prevDisabled: boolean;
  public nextDisabled: boolean;
  private debouncedEmit = new Subject<string>();
  private dataSubs = new Subscription();

  public constructor(
    private periodService: NPeriodService,
    private cd: ChangeDetectorRef,
  ) {}

  private _date: Date;

  public get date(): Date {
    return this._date;
  }

  @Input()
  public set date(date: string | Date) {
    const updatedDate = parseDate(date);
    if (isSameDay(this.date, updatedDate)) {
      return;
    }

    this._date = updatedDate;

    if (!isEqual(this.datePickerDate, updatedDate)) {
      this.datePickerDate = updatedDate;
    }

    this.dateFormat = format(this.date, 'eeeeee, d MMM');
    this.determinePeriod();
    this.updateButtonState();
  }

  private _minDate: Date;

  public get minDate() {
    return this._minDate;
  }

  @Input()
  public set minDate(value) {
    this._minDate = startOfDay(parseDate(value));
  }

  private _maxDate: Date;

  public get maxDate() {
    return this._maxDate;
  }

  @Input()
  public set maxDate(value) {
    this._maxDate = startOfDay(parseDate(value));
  }

  private _periodType: PeriodType = 'day';

  public get periodType() {
    return this._periodType;
  }

  @Input()
  public set periodType(periodType) {
    this._periodType = periodType;
    this.determinePeriod();
  }

  public ngOnInit(): void {
    this.dataSubs.add(
      this.debouncedEmit.pipe(debounceTime(200)).subscribe((date) => {
        this.dateChange.emit(date);
      }),
    );

    this.dataSubs.add(
      this.periodService.isLoading.pipe(distinctUntilChanged()).subscribe((isLoading) => {
        if (this.isLoading === isLoading || !this.hasLoadingState) {
          this.updateButtonState();
          return;
        }

        this.setLoading(isLoading);
        this.cd.detectChanges();
      }),
    );

    this.dateFormat = format(this.date, 'eeeeee, d MMM');
    this.cd.detectChanges();
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public changeDate(date: Date) {
    if (!isSameDay(this.date, date)) {
      this.date = date;
      this.setLoading(true);

      const emitDate = format(date, 'yyyy-MM-dd');

      if (this.delay) {
        this.debouncedEmit.next(emitDate);
      } else {
        this.dateChange.emit(emitDate);
      }
    }

    this.dateFormat = format(this.date, 'eeeeee, d MMM');
    this.cd.detectChanges();
  }

  public today(): void {
    this.changeDate(new Date());
  }

  public pickDate(date: Date) {
    this.overlayPanel.hide();
    this.changeDate(date);
  }

  public nextDate() {
    this.changeDate(nextDate(this.date, this.periodType));
  }

  public prevDate() {
    this.changeDate(previousDate(this.date, this.periodType));
  }

  public isPrevDisabled() {
    if (this.isLoading) {
      return true;
    }

    if (!this.minDate) {
      return false;
    }

    return isBefore(previousDate(this.date, this.periodType), this.minDate);
  }

  public isNextDisabled() {
    if (this.isLoading) {
      return true;
    }

    if (!this.maxDate) {
      return false;
    }

    return isAfter(nextDate(this.date, this.periodType), this.maxDate);
  }

  private setLoading(loading: boolean) {
    if (!this.periodService.getPeriod() || !this.hasLoadingState) {
      this.isLoading = false;
      this.updateButtonState();
      return;
    }
    this.isLoading = loading;
    this.updateButtonState();
    this.periodService.isLoading.next(loading);
  }

  private updateButtonState() {
    this.prevDisabled = this.isPrevDisabled();
    this.nextDisabled = this.isNextDisabled();
  }

  private determinePeriod() {
    if (!this.date || !this.periodType) {
      return;
    }

    const period = getPeriod(this.periodType, this.date);
    this.periodStart = period.start;
    this.periodEnd = period.end;
  }
}
