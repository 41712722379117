<div class="date-pager btn-group">
  <button
    type="button"
    class="date-pager__item btn btn-default btn-large hidden-xs-down flex h-[34px] items-center"
    (click)="prevDate()"
    [disabled]="prevDisabled"
  >
    <icon class="si" svgIcon="prev"></icon>
  </button>

  <button
    *ngIf="periodType === 'day'"
    (click)="overlayPanel.toggle($event)"
    type="button"
    class="date-pager__item btn btn-default btn-large flex h-[34px] items-center"
  >
    {{ dateFormat }}
  </button>

  <button
    *ngIf="periodType !== 'day'"
    (click)="overlayPanel.toggle($event)"
    type="button"
    class="date-pager__item btn btn-default btn-large flex h-[34px] items-center"
  >
    {{ periodStart | date: 'd MMM' }} - {{ periodEnd | date: 'd MMM' }}
  </button>

  <p-overlayPanel #overlayPanel>
    <ng-template pTemplate>
      <p-calendar
        [clearButtonStyleClass]="'sb-calendar-hide-clear'"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [(ngModel)]="datePickerDate"
        [disabled]="isLoading"
        [showButtonBar]="true"
        [firstDayOfWeek]="1"
        [showClear]="false"
        [showWeek]="true"
        [inline]="true"
        (onSelect)="pickDate(datePickerDate)"
      ></p-calendar>
    </ng-template>
  </p-overlayPanel>

  <button
    type="button"
    class="date-pager__item btn btn-default btn-large hidden-xs-down flex h-[34px] items-center"
    (click)="nextDate()"
    [disabled]="nextDisabled"
  >
    <icon class="si" svgIcon="next"></icon>
  </button>
</div>
